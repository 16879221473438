@import "../../styles/partials/init";

.introPanel {
	background: transparent url("../../assets/marius-masalar-wide.jpg") center top/cover no-repeat;
	border-bottom: 10px solid $cb-blue-1;
	box-sizing: border-box;
	display: block;
	margin: 0;
	padding: 40px 20px;
	position: relative;
	width: 100%;
	.introPanelInner {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		gap: 20px;
		margin: 0 auto;
		max-width: $page-max-width;
		padding: 0;
		position: relative;
		.eventList {
			background-color: transparent;
			flex: 0 0 340px;
			margin: 0 0 30px 0;
			min-height: 300px;
			.donateContainer {
				margin-top: 20px;
			}
		}
		.featureRegion {
			background-color: transparent;
			flex: 1 1;
			margin: 0 0 30px 0;
		}
		:global(.photoAttribution) {
			bottom: 0;
			left: 0;
			position: absolute;
		}
	}
}

.leadershipPanel {
	background-color: $color-black;
	background: linear-gradient(to bottom, $color-black-70 0%,$color-black 100%);
	box-sizing: border-box;
	padding: 30px 20px;
	width: 100%;
	.leadershipPanelInner {
		margin: 0 auto;
		max-width: $page-max-width;
		:global(h2) {
			color: $color-white;
		}
		.leadershipPanelList {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			gap: 20px;
			margin-top: 20px;
			.leadershipPanelItem {
				cursor: pointer;
				flex: 1 1;
				min-width: 175px;
				&:hover {
					.leadershipPanelPhotoHolder {
						img {
							border-color: $cb-blue-2;
						}
						.leadershipPanelPhotoNameplate {
							background-color: $cb-blue-1;
						}
					}
				}
				.leadershipPanelPhotoHolder {
					position: relative;
					width: 100%;
					img {
						border: 1px solid $color-white-60;
						display: block;
						height: relative;
						width: 100%;
					}
					.leadershipPanelPhotoNameplate {
						background-color: $cb-blue-1-70;
						bottom: 10px;
						box-sizing: border-box;
						left: 10px;
						min-height: 26px;
						padding: 3px 5px;
						position: absolute;
						right: 10px;
						:global(p) {
							color: $color-white;
							font-weight: $font-weight-semibold;
						}
						.leadershipPanelPhotoIndicator {
							border-color: transparent transparent $color-white transparent;
							border-style: solid;
							border-width: 0 0 16px 16px;
							bottom: 5px;
							height: 0;
							position: absolute;
							right: 5px;
							width: 0;
						}
						.leadershipPanelPhotoTitle {
							color: $color-white;
							font-size: 12px;
							font-style: italic;
							line-height: 15px;
						}
					}
				}
				.leadershipPanelCaptionHolder {
					line-height: 1.2em;
					margin-top: 5px;
					// text-align: right;
				}
			}
		}
	}
}

.ourStoryPanel {
	background-color: $color-black;
	background: linear-gradient(to bottom, $color-black-70 0%,$color-black 100%);
	box-sizing: border-box;
	padding: 30px 20px;
	width: 100%;
	.ourStoryPanelInner {
		display: flex;
		flex-direction: row;
		gap: 20px;
		margin: 0 auto;
		max-width: $page-max-width;
		.ourStoryPanelPhoto {
			flex: 1;
			img {
				border: 1px solid $color-white-60;
				display: block;
				height: relative;
				width: 100%;
			}
			.ourStoryCaptionHolder {
				// text-align: right;;
			}
		}
		.ourStoryPanelText {
			flex: 2;
			:global(a) {
				color: $color-links-light;
			}
			:global(h2) {
				color: $color-white;
			}
			:global(p) {
				color: $color-white;
				font-size: 16px;
				margin-top: 16px;
			}
		}
	}
}

.photosPanel {
	background-color: $color-white;
	background: linear-gradient(to bottom, $color-white 0%,$color-gray-light 100%);
	box-sizing: border-box;
	padding: 30px 20px;
	width: 100%;
	.photosPanelInner {
		margin: 0 auto;
		max-width: $page-max-width;
		:global(a) {
			font-weight: $font-weight-semibold;
			text-decoration: none;
			&:hover {
				color: $color-black;
			}
		}
		:global(p) {
			&:first-of-type {
				margin-top: 14px;
			}
		}
		.photosPanelList {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			gap: 20px;
			margin-top: 20px;
			.photosPanelItem {
				flex: 1 1;
				min-width: 175px;
				:global(.photoAttribution) {
					color: $color-black;
				}
				img {
					border: 1px solid $cb-blue-1;
					display: block;
					height: relative;
					width: 100%;
				}
				.photosPanelCaptionHolder {
					// text-align: right;
				}
			}
		}
	}
}

.recommendationsPanel {
	background-color: $color-white;
	background: linear-gradient(to bottom, $color-white 0%,$color-gray-light 100%);
	box-sizing: border-box;
	padding: 30px 20px;
	width: 100%;
	.recommendationsPanelInner {
		margin: 0 auto;
		max-width: $page-max-width;
		h2 {
			margin-bottom: 4px;
		}
		:global(h3) {
			color: $cb-blue-1;
			font-size: 15px;
			margin-bottom: 4px;
			text-transform: none;
		}
		.recommendationsPanelList {
			border-top: 1px solid $color-gray-dark;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			gap: 20px;
			padding-top: 20px;
			margin-top: 10px;
			.recommendationsPanelItem {
				flex: 1 1;
				max-width: 50%;
				min-width: 350px;
				.recommendationsPanelAttribution {
					font-style: italic;
					margin-top: 10px;
					// text-align: right;
				}
			}
		}
	}
}

.transformPanel {
	background-color: $color-white;
	background: linear-gradient(to bottom, $color-white 0%,$color-gray-light 100%);
	box-sizing: border-box;
	padding: 30px 20px;
	width: 100%;
	.transformPanelInner {
		margin: 0 auto;
		max-width: $page-max-width;
		.transformPanelHead {
			display: flex;
			flex-direction: row;
			gap: 20px;
			.transformHeadColumn1 {
				flex: 1;
				text-align: left;
			}
			.transformHeadColumn2 {
				flex: 1;
				color: $color-gray-extraDark;
				font-family: $base-font-family;
				font-size: 18px;
				font-weight: $font-weight-regular;
				line-height: 22px;
				text-align: right;
			}
		}
		.transformPanelList {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			gap: 30px;
			margin-top: 30px;
			.transformListColumn {
				flex: 1 1;
				min-width: 175px;
				:global(h3) {
					text-transform: none;
				}
				:global(p) {
					&:first-of-type {
						margin-top: 10px;
					}
				}
				:global(.photoAttribution) {
					color: $color-black;
				}
				img {
					border: 1px solid $cb-blue-1;
					display: block;
					height: relative;
					width: 100%;
				}
				.transformListItemCaptionHolder {
					// text-align: right;
				}
			}
		}
	}
}

/* Responsive Breakpoints */
@media (max-width: 958px) {
	.introPanel {
		background: transparent url("../../assets/marius-masalar-narrow.jpg") center top/cover no-repeat;
		.introPanelInner {
			margin: 0;
			width: auto;
			.featureRegion {
				margin-bottom: 0;
			}
		}
	}
}

@media (max-width: 760px) {
	.recommendationsPanel {
		.recommendationsPanelInner {
			.recommendationsPanelList {
				.recommendationsPanelItem {
					max-width: none;
				}
			}
		}
	}
}



@media (max-width: 675px) {
	.ourStoryPanel {
		.ourStoryPanelInner {
			.ourStoryPanelPhoto {
				display: none;
			}
		}
	}
}

@media (max-width: $page-narrow) {
	.introPanel {
		background: transparent url("../../assets/marius-masalar-narrow.jpg") center top/cover no-repeat;
		padding: 20px;
		.introPanelInner {
			flex-direction: column;
			margin: 0;
			width: auto;
			.eventList {
				flex: 1 1;
				min-height: 200px;
				width: auto;
			}
		}
	}
	.leadershipPanel {
		padding: 20px;
	}
	.ourStoryPanel {
		padding: 20px;
		.ourStoryPanelInner {
			.ourStoryPanelText {
				:global(p) {
					color: $color-white;
					font-size: 14px;
					margin-top: 14px;
				}
			}
		}
	}
	.photosPanel {
		padding: 20px;
	}
	.transformPanel {
		padding: 20px;
		.transformPanelHead {
			.transformHeadColumn2 {
				font-size: 14px;
				line-height: 22px;
			}
		}
		.transformPanelList {
			gap: 20px;
			margin-top: 20px;
		}
	}
}
