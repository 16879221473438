@import "../../styles/partials/init";

.contactPage {
	display: flex;
	flex-direction: row;
	gap: 20px;
	.contactPageMain {
		flex: 1;
		h2 {
			margin-bottom: -8px;
			margin-top: 30px;
		}
		.mailingListForm {
			input[type="submit"] {
				background-color: $color-links-dark;
				border: none;
				border-radius: 5px;
				box-sizing: border-box;
				color: $color-white;
				cursor: pointer;
				display: block;
				font-family: $simple-font-family;
				font-size: $base-font-size;
				font-weight: $font-weight-regular;
				height: 28px;
				line-height: 28px;
				margin: 10px 0 0 0;
				padding: 0 15px;
				white-space: nowrap;
				&:hover {
					background-color: $color-black;
				}
				&:disabled {
					opacity: 0.5;
					pointer-events: none;
				}
			}
			label {
				display: block;
				font-weight: $font-weight-bold;
				margin-top: 10px;
				input[type="email"],
				input[type="text"] {
					border: 1px solid $color-gray-dark;
					border-radius: 3px;
					display: block;
					font-family: $simple-font-family;
					font-size: 16px;
					font-weight: $font-weight-regular;
					line-height: 20px;
					margin-top: 3px;
					padding: 3px 6px;
					width: 250px;
				}
			}
			.mailchimpAlertError,
			.mailchimpAlertSending,
			.mailchimpAlertSuccess {
				display: block;
				color: $cb-blue-1;
				font-weight: $font-weight-bold;
				margin: 10px 0 0 0;
			}
			.mailchimpAlertError {
				color: $cb-red;
			}
			.mailchimpAlertSuccess {
				color: $cb-green;
			}
		}
	}
	.contactPageSide {
		flex: 0 0 200px;
		width: 200px;
		img {
			width: 100%;
		}
	}
}

/* Responsive Breakpoints ----------------------------------------------------------------------- */

@media (max-width: 750px) {
	.contactPage {
		.contactPageSide {
			display: none;
		}
	}
}
