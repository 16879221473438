/*
Global Sass variables

This partial loads before any other (see main.scss for load order)
*/

//
// Page Width
// These are our four common page widths for screen "modes". The content, banner and footer
// should match these values, depending on the viewport width
//
$page-narrow: 500px;
$page-min-width: 400px;
$page-max-width: 885px;

//
// Common Margin/Padding
//
$margin-1: 10px;
$margin-2: 20px;

//
// Layers (z-indexes)
//
$layer-content: 10; // applied to body
$layer-modal-overlay: 50;
$layer-nav-list: 60; // flyout navigation

//
// Common Border Radius
//
$base-radius: 5px;

//
// Font Weights
// Because one of our fonts uses weights that are best expressed numerically, we normalize all font
// weight specifications using these variables.
//
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-semibold: 500;
$font-weight-bold: 600;
$font-weight-extrabold: 700;

//
// Base Font Definition
// Most application text uses this family, with three possible sizes.
//
$base-font-family: "Open Sans", "Verdana", "Helvetica", sans-serif;
$base-font-weight: $font-weight-regular;
$base-font-size: 14px;
$base-font-size-narrow: 13px;
$base-line-height: 1.36;
$simple-font-family: "Verdana", "Helvetica", sans-serif;

//
// Mouse text
// Attributions, footnotes, etc.
//
$mousetext-family: "Verdana", "Helvetica", sans-serif;
$mousetext-font-size: 11px;
$mousetext-line-height: 1.2;

//
// Transitions
//
$transition-fast: 0.4s;

//
// Incorporate all the modules
//
@import "../modules/all";
