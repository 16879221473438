/*
DOM reset to normalize browsers
*/

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, 
acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, 
sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, 
caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, 
figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, 
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
a, input, select, textarea, button {
	box-sizing: border-box;
	margin: 0;
	&:focus {
		outline: none;
	}
	&:visited {
		outline: none;
	}
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after, q:before, q:after {
	content: '';
	content: none;
}
em {
	font-style: italic;
}
strong {
	font-weight: bold;
}

sub {
	vertical-align: sub;
	font-size: smaller;
}
sup {
	vertical-align: super;
	font-size: smaller;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
