/*
This generates base.css
*/
@import "partials/init";
@import "partials/reset";
@import "partials/layout";

#root {
	display: flex;
	flex-direction: column;
	height: 100vh;
	min-width: $page-min-width;
	.appContent {
		flex: 1 0 auto;
	}
}

a, a:visited {
	color: $color-links-dark;
	text-decoration: underline;
	&:hover {
		color: $color-black;
		text-decoration: underline;
	}
}

h1 {
	color: $color-gray-extraDark;
	font-family: $base-font-family;
	font-size: 40px;
	font-weight: $font-weight-regular;
	line-height: 22px;
	margin: 18px 0 8px 0;
	padding: 0;
	&:first-child {
		margin-top: 0;
	}
}

h2 {
	color: $color-gray-extraDark;
	font-family: $base-font-family;
	font-size: 18px;
	font-weight: $font-weight-bold;
	line-height: 22px;
	margin: 18px 0 8px 0;
	padding: 0;
	text-transform: uppercase;
	&:first-child {
		margin-top: 0;
	}
}

h3 {
	font-family: $base-font-family;
	font-size: $base-font-size;
	font-weight: $font-weight-bold;
	line-height: $base-line-height;
	margin: 14px 0 8px 0;
	padding: 0;
	text-transform: uppercase;
	&:first-child {
		margin-top: 0;
	}
}

i {
	font-style: italic;
}

p {
	color: $color-black;
	font-size: $base-font-size;
	line-height: $base-line-height;
	margin: 14px 0 0 0;
	padding: 0;
	&:first-of-type {
		margin-top: 0;
	}
	&.intro {
		color: $cb-blue-1;
		font-size: 20px;
		font-weight: $font-weight-regular;
	}
}

.modalOverlay {
	align-items: center;
	background-color: $color-black-60;
	bottom: 0;
	display: flex;
	height: 100%;
	justify-content: center;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	width: 100%;
	z-index: $layer-modal-overlay;
}

.photoAttribution {
	color: $color-white-80;
	font-family: $mousetext-family;
	font-size: $mousetext-font-size;
}

/* Responsive Breakpoints */
@media (max-width: $page-narrow) {
	h2 {
		font-size: 16px;
		line-height: 20px;
	}

	p {
		font-size: $base-font-size-narrow;
		margin: 13px 0 0 0;
		&.intro {
			font-size: 16px;
		}
	}
}

/* Print specitic */
@media print {
	.noprint {
		display: none;
	}
}