/*
Application-wide styles
*/

/* Common Overrides ----------------------------------------------------------------------------- */

a, a:visited {
	color: $color-links-dark;
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
}

body {
	@include hyphenation-off();
	color: $color-black;
	font-family: $base-font-family;
	font-size: $base-font-size;
	font-weight: $base-font-weight;
	height: 100%;
	line-height: $base-line-height;
	margin: 0;
	padding: 0;
	z-index: $layer-content;
}

html {
	box-sizing: border-box;
	height: 100%;
	width: 100%;
}

/*  Layout Classes ------------------------------------------------------------------------------- */
.container {
	margin: 0 auto;
	max-width: $page-narrow;
	min-width: $page-min-width;
	&.padded {
		padding: 0 20px;
	}
}

/* Global Utility Classes ----------------------------------------------------------------------- */

.modalOverlay {
	background-color: $color-black-60;
	display: none;
	height: 100%;
	left: 0;
	overflow: auto;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: $layer-modal-overlay;
}

.disabled {
	opacity: 0.5;
	pointer-events: none;
	cursor: default;
}
