@import "../../../styles/partials/init";

.feature {
	background: #4c4b16 url('./images/DayOfBrass2025.svg') center 0/350px auto no-repeat;
	border-radius: 17px;
	box-sizing: border-box;
	cursor: pointer;
	padding: 275px 5px 10px 5px;
	text-align: center;
	width: 350px;
	&:hover {
		background-color: #201f09;
	}
	:global(p) {
		color: #fff;
	}
}
