@import "../../../styles/partials/init";

.zoomBox {
	background-color: $color-white;
	box-sizing: border-box;
	display: block;
	font-size: 0;
	max-width: $page-max-width;
	min-width: 390px;
	padding: 10px;
	position: relative;
	text-align: left;
	width: 80vw;
	&.closed {
		display: none;
	}
	.zoomBoxImage {
		background-color: transparent;
		box-sizing: border-box;
		width: 100%;
		img {
			width: 100%;
		}
	}
	.zoomBoxCloser {
		background: $color-links-dark url("../../../assets/menu-close.png") center center/34px 34px no-repeat;
		border: 2px solid $color-white;
		border-radius: 25px;
		box-sizing: border-box;
		cursor: pointer;
		display: block;
		font-size: 0;
		height: 50px;
		line-height: 0;
		margin: 0;
		outline: none;
		overflow: hidden;
		position: absolute;
		right: 15px;
		text-decoration: none;
		text-indent: -15000px;
		top: 15px;
		width: 50px;
		&:hover {
			background-color: black;
		}
	}
}
