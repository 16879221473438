@import "../../styles/partials/init";

.page {
	display: flex;
	flex-direction: row;
	gap: 20px;
	.pageMain {
		flex: 1;
		order: 1;
		.pageTop {
			:global(h1) {
				font-weight: $font-weight-extrabold;
				text-align: center;
			}
			:global(h2) {
				color: $dob-brown;
				font-size: 20px;
				font-weight: $font-weight-regular;
				line-height: 1.3;
				text-align: center;
				text-transform: none;
			}
		}
		.section {
			margin-top: 25px;
			&:first-child {
				margin-top: 50px;
			}
			table {
				width: 100%;
				tbody {
					tr {
						&:nth-child(odd) {
							td {
								background-color: $color-black-10;
							}
						}
						td {
							background-color: transparent;
							padding: 5px 8px;
							&:nth-child(1) {
								padding-right: 0;
								white-space: nowrap;
							}
							&:nth-child(2) {
								white-space: nowrap;
							}
							&:nth-child(3) {
								padding-left: 0;
								white-space: nowrap;
							}
						}
					}
				}
			}
			.bios {
				display: flex;
				flex-direction: row;
				line-height: $base-line-height;
				gap: 10px;
				.bio {
					flex: 1;
					text-align: center;
					img {
						width: 100%;
					}
					.name {
						font-size: $base-font-size;
						font-weight: $font-weight-bold;
						text-transform: uppercase;
					}
					.cred {
						font-size: $mousetext-font-size;
						font-style: italic;
					}
					.instrument {
						font-size: $base-font-size;
						font-weight: $font-weight-bold;
					}
				}
			}
			.footnote {
				font-size: $mousetext-font-size;
				margin-top: 10px;
			}
		}
	}
	.pageSide {
		order: 2;
		flex: 0 0 200px;
		text-align: center;
		:global(h2) {
			text-transform: none;
		}
		:global(h3) {
			margin-bottom: 5px;
		}
		:global(p) {
			margin-top: 0;
		}
		img {
			max-width: 250px;
			width: 100%;
		}
		.ffxLogo {
			margin: 10px 0 20px 0;
		}
	}
}

/* Responsive Breakpoints ----------------------------------------------------------------------- */

@media (max-width: 750px) {
	.page {
		flex-direction: column;
		.pageMain {
			order: 2;
		}
		.pageSide {
			border-bottom: 2px solid $dob-brown;
			order: 1;
			padding-bottom: 20px;
		}
	}
}
