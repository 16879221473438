/*
Sass utility code

Utilities that are useful throughout the site
*/

// Turn off text hyphenation in the block
@mixin hyphenation-off() {
	hyphens: none;
	word-break: normal !important;
	word-wrap: normal !important;
	-webkit-hyphens: manual !important;
	-moz-hyphens: manual !important;
	-ms-hyphens: manual !important;
	-ms-word-break: normal !important;
}

// Turn on text hyphenation in the block
@mixin hyphenation-on() {
	hyphens: auto;
	hyphenate-limit-lines: 1;
	hyphenate-limit-chars: 8 4 4;
	-moz-hyphens: auto !important;
	-moz-hyphenate-limit-chars: 8 4 4;
	-moz-hyphenate-limit-lines: 1;
	-webkit-hyphens: auto !important;
	-webkit-hyphenate-limit-chars: 8 4 4;
	-webkit-hyphenate-limit-lines: 1;
	-ms-hyphens: auto !important;
	-ms-hyphenate-limit-chars: 8 4 4;
	-ms-hyphenate-limit-lines: 1;
}
