@import "../../styles/partials/init";

.photosGrid {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 10px;
	.photo {
		flex: 1 1;
		min-width: 200px;
		text-align: center;
		.photoBox {
			aspect-ratio: 1.5/1;
			background-color: transparent;
			background-position: center;
			background-size: cover;
			box-sizing: border-box;
			cursor: pointer;
			position: relative;
			width: 100%;
			&:hover {
				.photoBoxIndicator {
					display: block;
				}
			}
			.photoBoxIndicator {
				border-color: transparent transparent $color-white transparent;
				border-style: solid;
				border-width: 0 0 25px 25px;
				bottom: 5px;
				display: none;
				height: 0;
				position: absolute;
				right: 5px;
				width: 0;
			}
		}
		:global(.photoAttribution) {
			color: $color-gray-medium;
			margin-top: 2px;
			text-align: right;
		}
	}
}
