@import "../../../styles/partials/init";

.header {
	background-color: $color-white;
	border-bottom: 10px solid $cb-blue-1;
	box-sizing: border-box;
	height: 120px;
	margin: 0;
	padding: 40px 20px;
	position: sticky;
	top: 0;
	width: 100%;
	z-index: $layer-content;
	.headerInner {
		display: flex;
		flex-direction: row;
		margin: 0 auto;
		max-width: $page-max-width;
		position: relative;
		.logoHolder {
			align-items: left;
			flex: 1;
			.logo {
				background: transparent url("./images/cathedral-brass-wordmark-1.svg") left top no-repeat;
				background-size: auto 40px;
				display: block;
				height: 40px;
				width: 318px;
			}
		}
		.menuHolder {
			align-items: center;
			flex: 0;
			min-width: 40px;
			width: 40px;
		}
	}
}

/* Responsive Breakpoints ----------------------------------------------------------------------- */

@media (max-width: $page-narrow) {
	.header {
		.headerInner {
			.logoHolder {
				.logo {
					background-image: url("./images/cathedral-brass-wordmark-3.svg");
					width: 264px;
				}
			}
		}
	}
}
