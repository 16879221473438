@import "../../styles/partials/init";

.page {
	display: flex;
	flex-direction: row;
	gap: 20px;
	.pageMain {
		flex: 1;
		order: 1;
		.pageTop {
			:global(h1) {
				font-weight: $font-weight-extrabold;
				line-height: $base-line-height;
				text-align: center;
			}
			:global(h2) {
				color: $dob-brown;
				font-size: 20px;
				font-weight: $font-weight-regular;
				line-height: 1.3;
				text-align: center;
				text-transform: none;
			}
		}
		.section {
			margin-top: 25px;
			p {
				&.leadIn {
					font-size: 18px;
				}
			}
			sup {
				vertical-align: super;
				font-size: 10px;
				line-height: 1;	
			}
			ul, ol {
				list-style-type: disc;
				margin: 14px 0 0 1.5em;
				li {
					margin-top: 4px;
				}
			}
			ol {
				list-style-type: decimal;
			}
			.footnote {
				font-size: $mousetext-font-size;
				margin-top: 10px;
			}
		}
	}
	.pageSide {
		order: 2;
		flex: 0 0 250px;
		text-align: center;
		img {
			max-width: 250px;
			width: 100%;
		}
	}
}

/* Responsive Breakpoints ----------------------------------------------------------------------- */

@media (max-width: 750px) {
	.page {
		flex-direction: column;
		.pageMain {
			// order: 2;
		}
		.pageSide {
			display: none;
		}
	}
}
