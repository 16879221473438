@import "../../../styles/partials/init";

.donateButton {
	background-color: $cb-orange-60;
	border: 1px solid $color-white-60;
	border-radius: 5px;
	box-sizing: border-box;
	display: block;
	padding: 8px 20px;
	text-align: center;
	text-decoration: none;
	width: 100%;
	&:hover {
		background-color: $cb-orange-40;
		text-decoration: none;
	}
	:global(h3) {
		color: $color-white;
		font-size: 18px;
		font-weight: $font-weight-bold;
		line-height: 18px;
		margin: 0;
		text-transform: none;
	}
	:global(p) {
		color: $color-white;
		font-size: 12px;
		font-weight: $font-weight-bold;
		line-height: 16px;
		margin: 5px 0 0 0;
		padding: 0;
		&:first-of-type {
			margin-top: 6px;
		}
	}
}
