@import "../../../styles/partials/init";

.treble, .treble:visited {
	background: transparent url("./images/treble.png") top left/40px 40px no-repeat;
	border: none;
	box-sizing: border-box;
	cursor: pointer;
	display: inline-block;
	font-size: 0;
	height: 40px;
	line-height: 0;
	overflow: hidden;
	text-decoration: none;
	text-indent: -15000px;
	vertical-align: top;
	width: 40px;
	&:hover {
		text-decoration: none;
	}
}

/* Responsive Breakpoints ----------------------------------------------------------------------- */

