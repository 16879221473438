@import "../../styles/partials/init";

.donatePage {
	display: flex;
	flex-direction: row;
	gap: 20px;
	.pageMain {
		flex: 1;
		:global(h2) {
			margin-bottom: -8px;
			margin-top: 30px;
		}
		.paypalForm {
			margin-top: 20px;
			text-align: center;
		}
	}
	.pageSide {
		flex: 0 0 300px;
		width: 300px;
		img {
			width: 100%;
		}
	}
}

/* Responsive Breakpoints ----------------------------------------------------------------------- */

@media (max-width: 700px) {
	.donatePage {
		.pageSide {
			display: none;
		}
	}
}
