@import "../../../styles/partials/init";

.navigationPanel {
	background-color: $color-black-80;
	border: 1px solid $color-white-40;
	box-sizing: border-box;
	display: none;
	height: auto;
	overflow: hidden;
	padding: 0;
	position: absolute;
	right: -25px;
	top: -25px;
	transition: $transition-fast;
	width: 200px;
	z-index: $layer-nav-list;
	ul {
		list-style-type: none;
		color: $color-white;
		font-size: 16px;
		font-weight: $font-weight-bold;
		line-height: $base-line-height;
		margin: 90px 20px 20px 20px;
		padding: 0;
		li {
			margin: 10px 0 0 0;
			padding: 0;
			text-indent: 0;
			white-space: nowrap;
			&:first-child {
				margin-top: 0;
			}
			a {
				color: $color-white;
				font-weight: $font-weight-light;
				padding: 0;
				text-decoration: none;
				&:hover {
					color: $color-white;
					text-decoration: underline;
				}
			}
		}
	}
}

.navigationPanelClose {
	background: transparent url("../../../assets/menu-close.png") 4px 4px/35px 35px no-repeat;
	border: 1px solid transparent;
	box-sizing: border-box;
	display: block;
	font-size: 0;
	height: 45px;
	line-height: 0;
	margin: 20px;
	overflow: hidden;
	position: absolute;
	right: 0;
	text-decoration: none;
	text-indent: -15000px;
	top: 0;
	width: 45px;
	&:hover {
		text-decoration: none;
		border: 1px solid $color-white;
	}
}

.navigationPanelOpen {
	@extend .navigationPanel;
	display: block;
	// box-sizing: border-box;
	// overflow-x: hidden;
	// overflow-y: auto;
	// right: 0;
}

/* Responsive Breakpoints ----------------------------------------------------------------------- */

@media (max-width: $page-narrow) {
	.navigationPanelClose {
		right: -2px;
	}
}
