@import "../../styles/partials/init";

.calendarDisplay {
	display: flex;
	flex-direction: row;
	gap: 20px;
	.mainColumn {
		flex: 1;
		.eventsList {
			h3 {
				border-top: 5px solid $cb-blue-1;
				color: $color-black;
				font-size: 16px;
				margin: 20px 0 5px;
				padding: 5px 0 0;
				text-transform: none;
			}
			.event {
				display: flex;
				flex-direction: row;
				gap: 9px;
				margin-top: 20px;
				width: 100%;
				.eventDateCard {
					background-color: $cb-umber;
					border-radius: 5px;
					box-sizing: border-box;
					flex: 0 0 46px;
					height: 51px;
					margin-top: 4px;
					padding: 8px 0 0 0;
					text-align: center;
					.eventDateCardDay {
						color: $color-white;
						display: block;
						font-size: 25px;
						font-weight: $font-weight-light;
						line-height: 25px;
						margin-top: 2px;
					}
					.eventDateCardMonth {
						color: $color-white;
						display: block;
						font-size: 11px;
						font-weight: $font-weight-bold;
						line-height: 11px;
						text-transform: uppercase;
					}
				}
				.eventDetails {
					color: $color-black;
					flex: 1;
					font-size: $base-font-size;
					font-weight: $font-weight-regular;
					line-height: $base-line-height;
					margin: 0;
					padding: 0;
					text-align: left;
					a {
						color: $color-links-dark;
						text-decoration: underline;
						&:hover {
							color: $color-black;
							text-decoration: underline;
						}
					}
					.eventDetailsNotes {
						font-size: 12px;
						margin-top: 3px;
					}
					.eventDetailsTime {
						font-style: italic;
					}
					.eventDetailLocation0 {
						font-weight: $font-weight-bold;
					}
					.eventDetailsTitle {
						color: $cb-blue-1;
						font-size: 14px;
						font-weight: $font-weight-bold;
						line-height: 19px;
						a {
							color: $color-links-light;
							text-decoration: none;
							&:after {
								content: "\00BB";
								margin-left: 6px;
							}
							&:hover {
								color: $cb-orange;
								text-decoration: none;
							}
						}
					}
				}
			}
			.notFound {
				margin-top: 10px;
				text-align: center;
			}
		}
		.rangeControls {
			background-color: $color-gray-light;
			display: block;
			padding: 5px;
			text-align: center;
			label {
				font-size: $base-font-size;
				margin: 0 4px 0 10px;
				&:first-child {
					margin-left: 0;
				}
			}
		}
	}
	.sidebar {
		flex: 0 0 250px;
	}
}

@media (max-width: 700px) {
	.calendarDisplay {
		flex-direction: column;
		.sidebar {
			flex: 0;
		}
	}
}
