@import "../../../styles/partials/init";

.footer {
	background-color: $color-gray-extraDark;
	box-sizing: border-box;
	display: block;
	flex: 0 0 105px;
	height: 105px;
	margin: 0;
	min-height: 105px;
	padding: 0 20px;
	width: 100%;
	.footerInner {
		box-sizing: border-box;
		color: $color-white;
		display: flex;
		flex-direction: row;
		font-size: $mousetext-font-size;
		font-weight: $font-weight-regular;
		line-height: $base-line-height;
		margin: 0 auto;
		max-width: $page-max-width;
		padding: 29px 0 0 0;
		:global(a) {
			color: $color-white;
			margin: 0;
			padding: 0;
			text-decoration: underline;
			&:hover {
				color: $color-white-80;
				text-decoration: underline;
			}
		}
		.column1 {
			flex: 0 0 auto;
			flex-direction: row;
			flex-wrap: nowrap;
			gap: 10px;
			text-align: left;
			.mailingListRow1 {
				display: block;
			}
			.mailingListRow2 {
				display: block;
				margin: 5px 0 0 0;
				a {
					background-color: $color-links-light;
					border: none;
					border-radius: 10px;
					box-sizing: border-box;
					color: $color-black;
					cursor: pointer;
					display: inline-block;
					font-family: $simple-font-family;
					font-size: $mousetext-font-size;
					font-weight: $font-weight-regular;
					height: 20px;
					line-height: 20px;
					margin: 0;
					padding: 0 10px;
					text-decoration: none;
					white-space: nowrap;
					&:hover {
						background-color: $color-gray-light;
						text-decoration: none;
					}
				}
			}
		}
		.column2 {
			flex: 1 1 auto;
			text-align: right;
			.copyright {
				margin-top: 14px;
			}
		}
		.listContent {
			border-left: 1px solid $color-white;
			display: inline-block;
			margin: 0 0 0 10px;
			padding: 0 0 0 10px;
			&:first-of-type {
				border-left: none;
				margin-left: 0;
				padding-left: 0;
			}
		}
	}
}

/* Responsive Breakpoints ----------------------------------------------------------------------- */

@media (max-width: 800px) {
	.footer {
		flex: 0 0 140px;
		height: 140px;
		min-height: 140px;
		.footerInner {
			flex-direction: column;
			padding-top: 20px;
			.column2 {
				text-align: left;
				margin-top: 10px;
				.copyright {
					margin-top: 5px;
				}
			}
		}
	}
}
