@import "../../../styles/partials/init";
@import "../../../styles/modules/colors";

.interiorPage {
	background: transparent;
	// border-top: 10px solid $cb-blue-1;
	font-family: $base-font-family;
	font-size: $mousetext-font-size;
	line-height: $base-line-height;
	.interiorPageTitle {
		background-color: $cb-blue-2;
		box-sizing: border-box;
		padding: 4px 20px 7px;
		.interiorPageTitleInner {
			color: $color-white;
			font-family: $base-font-family;
			font-size: 22px;
			font-weight: $font-weight-light;
			margin: 0 auto;
			max-width: $page-max-width;
		}
	}
	.interiorPageContent {
		box-sizing: border-box;
		font-size: $base-font-size;
		padding: 20px 20px;
		.interiorPageContentInner {
			margin: 0 auto;
			max-width: $page-max-width;
		}
	}
}

@media (max-width: $page-narrow) {
	.interiorPage {
		.interiorPageContent {
			padding: 20px;
		}
		.interiorPageTitle {
			padding: 3px 20px 6px;
			font-size: 18px;
		}
	}
}
