/*
	Color Palette
*/

// blacks and grays
$color-black: #000;
$color-black-10: rgba(0,0,0,0.1);
$color-black-20: rgba(0,0,0,0.2);
$color-black-60: rgba(0,0,0,0.6);
$color-black-70: rgba(0,0,0,0.7);
$color-black-80: rgba(0,0,0,0.8);
$color-gray-extraDark: #222;
$color-gray-dark: #333;
$color-gray-medium: #555;
$color-gray-light: #ddd;
$color-white: #fff;
$color-white-40: rgba(255,255,255,0.4);
$color-white-60: rgba(255,255,255,0.6);
$color-white-80: rgba(255,255,255,0.8);

// blues
$cb-blue-1: #2f457d;
$cb-blue-1-70: rgba(47,69,125,0.7);
$cb-blue-2: #6278b0;

// reds, oranges
$cb-orange: #fe5101;
$cb-orange-40: rgba(254,81,1,0.4);
$cb-orange-60: rgba(254,81,1,0.6);
$cb-red: #a00;
$cb-umber: #983101;
$dob-brown: #5e412f;

// greens
$cb-green: #090;
/*
	Functional Color Defintions
*/

// links
$color-links-light: #afd0de;
$color-links-dark: #093567;
$color-links-dark-hover: #072950;