@import "../../../styles/partials/init";

.bioBox {
	background-color: $color-white;
	border: 5px solid $cb-blue-1;
	box-sizing: border-box;
	display: block;
	padding: 10px;
	position: relative;
	text-align: left;
	width: 550px;
	&.closed {
		display: none;
	}
	:global(p) {
		font-size: 13px;
		margin-top: 13px;
		&:first-of-type {
			margin-top: 0;
		}
	}
	.bioBoxNameplate {
		background-color: $cb-blue-1;
		bottom: 10px;
		box-sizing: border-box;
		margin: 10px 0 0 0;
		min-height: 39px;
		padding: 3px 5px;
		position: relative;
		:global(p) {
			color: $color-white;
			font-weight: $font-weight-semibold;
		}
		.bioBoxCloser {
			background: transparent url("../../../assets/menu-close.png") -1px -1px/29px 29px no-repeat;
			border: 1px solid transparent;
			box-sizing: border-box;
			display: block;
			font-size: 0;
			height: 29px;
			line-height: 0;
			margin: 0;
			overflow: hidden;
			position: absolute;
			right: 5px;
			text-decoration: none;
			text-indent: -15000px;
			top: 5px;
			width: 29px;
			&:hover {
				text-decoration: none;
				border: 1px solid $color-white;
			}
		}
		.bioBoxTitle {
			color: $color-white;
			font-size: 12px;
			font-style: italic;
			line-height: 15px;
		}
	}

}

/* Responsive Breakpoints */
@media (max-width: 570px) {
	.bioBox {
		width: 380px;
	}
}