@import "../../styles/partials/init";

.youthPage {
	background: transparent url("./images/youthEnsemblePage.jpg") right top/200px auto no-repeat;
	padding-right: 220px;
	:global(h2) {
		font-weight: $font-weight-bold;
		margin-bottom: -8px;
		text-transform: none;
	}
	table {
		margin-top: 0;
		width: 100%;
		thead {
			tr {
				th {
					background-color: $color-black-60;
					border-right: 1px solid $color-white-40;
					color: $color-white-80;
					padding: 5px 8px;
					text-align: left;
					&:last-child {
						border-right: none;
					}
				}
			}
		}
		tbody {
			tr {
				&:nth-child(even) {
					td {
						background-color: $color-black-10;
					}
				}
				td {
					background-color: transparent;
					border-right: 1px solid $color-black-20;
					padding: 5px 8px;
					&:last-child {
						border-right: none;
					}
				}
				&:last-child {
					td {
						border-bottom: 1px solid $color-black-20;
					}
				}
			}
		}
	}
}

/* Responsive Breakpoints */
@media (max-width: 725px) {
	.youthPage {
		background-image: none;
		padding-right: 0;
	}
}