@import "../../../styles/partials/init";

.EventListInner {
	h3 {
		color: $color-white;
	}
	.calendarLink {
		margin-top: 10px;
		text-align: center;
		a {
			color: $color-links-light;
			font-size: 12px;
			line-height: 17px;
			text-decoration: none;
			&:hover {
				color: $color-links-light;
				text-decoration: underline;
			}
		}
	}
	.event {
		display: flex;
		flex-direction: row;
		gap: 9px;
		margin-top: 20px;
		width: 100%;
		&:first-of-type {
			margin-top: 0;
		}
		.eventDateCard, .eventDateCardYouTube {
			background-color: $color-white;
			border-radius: 5px;
			box-sizing: border-box;
			flex: 0 0 46px;
			height: 51px;
			margin-top: 4px;
			padding: 8px 0 0 0;
			text-align: center;
			.eventDateCardDay {
				color: $color-gray-dark;
				display: block;
				font-size: 25px;
				font-weight: $font-weight-light;
				line-height: 25px;
				margin-top: 2px;
			}
			.eventDateCardMonth {
				color: $color-gray-medium;
				display: block;
				font-size: 11px;
				font-weight: $font-weight-bold;
				line-height: 11px;
				text-transform: uppercase;
			}
		}
		.eventDateCardYouTube {
			background-image: url('./images/play-icon.svg');
			background-position: center center;
			background-repeat: no-repeat;
			background-size: 32px;
		}
		.eventDetails {
			color: $color-white;
			flex: 1;
			font-size: 11px;
			font-weight: $font-weight-regular;
			line-height: 15px;
			margin: 0;
			padding: 0;
			text-align: left;
			a {
				color: $color-white;
				text-decoration: none;
				&:hover {
					color: $cb-orange;
					text-decoration: none;
				}
			}
			.eventDetailsNotes {
				margin-top: 3px;
			}
			.eventDetailsTime {
				font-style: italic;
			}
			.eventDetailLocation0 {
				font-weight: $font-weight-bold;
			}
			.eventDetailsTitle {
				color: $color-links-light;
				font-size: 14px;
				font-weight: $font-weight-bold;
				line-height: 19px;
				a {
					color: $color-links-light;
					text-decoration: none;
					&:after {
						content: "\00BB";
						margin-left: 6px;
					}
					&:hover {
						color: $cb-orange;
						text-decoration: none;
					}
				}
			}
		}
	}
}
